// jobs and empoyers images
import AFIcon from '../assets/images/AFSealLogoNew.svg'
import stars from '../assets/images/stars.svg'
import volunteer from '../assets/images/volonteer.svg'
// job seekers images
import img1 from '../assets/images/jobSeekerImg1.webp'
import img2 from '../assets/images/jobSeekerImg2.webp'
import img3 from '../assets/images/jobSeekerImg3.webp'


export const jobsAndEmpoyersData = [
    {
        logo: AFIcon,
        logoW: '86px',
        logoH: '86px',
        header: 'Find Age Friendly Employers',
        description: 'These employers are committed to being the best places to work for employees at or above age 50.',
        link: 'https://search.retirementjobs.com/jobs?locale=en&page=1&sort=relevance&country=&state=&city=&zip=&latitude=&longitude=&keywords=&city_state_zip=&badge_filter=1&job_id='
    },
    {
        logo: stars,
        logoW: '184px',
        logoH: '86px',
        header: 'See Reviews',
        description: 'Find out if your employer is age-friendly, unwelcoming to workers age 50+, or somewhere in between. Leave a rating & review to share your experiences.',
        link: '/reviews'
    },
    {
        logo: volunteer,
        logoW: '86px',
        logoH: '86px',
        header: 'Volunteer',
        description: 'Volunteering is a great way to gain valuable experience and boost your career options.',
        link: 'https://search.retirementjobs.com/jobs?locale=en&page=1&sort=relevance&country=&state=&city=&zip=&latitude=&longitude=&keywords=&city_state_zip=&job_type_filter=Volunteer&job_id='
    }
]

export const jobSeekersData = [
    {
        img: img1,
        header: '3-minute pre-interview',
        description: 'Gauge initial interest and qualification for frequently posted positions.',
        link: '/pre-interview'
    },
    {
        img: img2,
        header: 'Webinars',
        description: 'Create account and receive invitations to attend all upcoming webinars.',
        link: '/webinars'
    },
    {
        img: img3,
        header: 'Career services',
        description: 'Find and get the position you want, no matter your career stage.',
        link: '/career-service'
    }
]

export const states = [
    { name: 'Alabama', abbrev: 'AL' },
    { name: 'Alaska', abbrev: 'AK' },
    { name: 'Arizona', abbrev: 'AZ' },
    { name: 'Arkansas', abbrev: 'AR' },
    { name: 'California', abbrev: 'CA' },
    { name: 'Colorado', abbrev: 'CO' },
    { name: 'Connecticut', abbrev: 'CT' },
    { name: 'Delaware', abbrev: 'DE' },
    { name: 'Florida', abbrev: 'FL' },
    { name: 'Georgia', abbrev: 'GA' },
    { name: 'Hawaii', abbrev: 'HI' },
    { name: 'Idaho', abbrev: 'ID' },
    { name: 'Illinois', abbrev: 'IL' },
    { name: 'Indiana', abbrev: 'IN' },
    { name: 'Iowa', abbrev: 'IA' },
    { name: 'Kansas', abbrev: 'KS' },
    { name: 'Kentucky', abbrev: 'KY' },
    { name: 'Louisiana', abbrev: 'LA' },
    { name: 'Maine', abbrev: 'ME' },
    { name: 'Maryland', abbrev: 'MD' },
    { name: 'Massachusetts', abbrev: 'MA' },
    { name: 'Michigan', abbrev: 'MI' },
    { name: 'Minnesota', abbrev: 'MN' },
    { name: 'Mississippi', abbrev: 'MS' },
    { name: 'Missouri', abbrev: 'MO' },
    { name: 'Montana', abbrev: 'MT' },
    { name: 'Nebraska', abbrev: 'NE' },
    { name: 'Nevada', abbrev: 'NV' },
    { name: 'New Hampshire', abbrev: 'NH' },
    { name: 'New Jersey', abbrev: 'NJ' },
    { name: 'New Mexico', abbrev: 'NM' },
    { name: 'New York', abbrev: 'NY' },
    { name: 'North Carolina', abbrev: 'NC' },
    { name: 'North Dakota', abbrev: 'ND' },
    { name: 'Ohio', abbrev: 'OH' },
    { name: 'Oklahoma', abbrev: 'OK' },
    { name: 'Oregon', abbrev: 'OR' },
    { name: 'Pennsylvania', abbrev: 'PA' },
    { name: 'Rhode Island', abbrev: 'RI' },
    { name: 'South Carolina', abbrev: 'SC' },
    { name: 'South Dakota', abbrev: 'SD' },
    { name: 'Tennessee', abbrev: 'TN' },
    { name: 'Texas', abbrev: 'TX' },
    { name: 'Utah', abbrev: 'UT' },
    { name: 'Vermont', abbrev: 'VT' },
    { name: 'Virginia', abbrev: 'VA' },
    { name: 'Washington', abbrev: 'WA' },
    { name: 'West Virginia', abbrev: 'WV' },
    { name: 'Wisconsin', abbrev: 'WI' },
    { name: 'Wyoming', abbrev: 'WY' }
];

export const categories = [
    { name: 'Accounting / Tax Preparers', abbrev: 'AL', link: '/jobs-for-seniors/category/Accounting-Tax-Preparers' },
    { name: 'Administrative / Clerical', abbrev: 'CT', link: '/jobs-for-seniors/category/Administrative-Clerical' },
    { name: 'Banking', abbrev: 'IL', link: '/jobs-for-seniors/category/Banking' },
    { name: 'Business Ownership ', abbrev: 'AK', link: '/jobs-for-seniors/category/Business-Ownership' },
    { name: 'Caregiving / Healthcare', abbrev: 'DE', link: '/jobs-for-seniors/category/Caregiving-Healthcare' },
    { name: 'Driver / Transportation', abbrev: 'AZ', link: '/jobs-for-seniors/category/Driver-transportation' },
    { name: 'Financial', abbrev: 'FL', link: '/jobs-for-seniors/category/Financial' },
    { name: 'Human Resources', abbrev: 'IN', link: '/jobs-for-seniors/category/Human-resources' },
    { name: 'Insurance', abbrev: 'AR', link: '/jobs-for-seniors/category/Insurance' },
    { name: 'Marketing', abbrev: 'GA', link: '/jobs-for-seniors/category/Marketing' },
    { name: 'Non Profit', abbrev: 'IA', link: '/jobs-for-seniors/category/Non-Profit' },
    { name: 'Quality Service Inspector', abbrev: 'CA', link: '/jobs-for-seniors/category/Quality-Service-Inspector' },
    { name: 'Retail', abbrev: 'HI', link: '/jobs-for-seniors/category/Retail' },
    { name: 'Sales', abbrev: 'KS', link: '/jobs-for-seniors/category/Sales' },
    { name: 'Security', abbrev: 'CO', link: '/jobs-for-seniors/category/Security' },
    { name: 'Service Representative', abbrev: 'KY', link: '/jobs-for-seniors/category/Service-Representative' },
    { name: 'Volunteer', abbrev: 'ID', link: '/jobs-for-seniors/category/Volunteer' },
    // { name: 'Other', abbrev: 'LA', link: '/jobs-for-seniors/category/Other' },
];



// categories.sort((a, b) => {
//     const nameA = a.name.toLowerCase();
//     const nameB = b.name.toLowerCase();
//     return nameA.localeCompare(nameB);
// });

export const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z", "0-9"];