export const statesData = [
  {
    state: "Alabama",
    currentStatistics:
      "Alabama ranks 22nd among all states in population of people over 65. For the number of job opportunities for seniors it ranks 31st. Alabama accounts for 1.6% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Alabama you can visit ",
        linkTitle: "the Dept. of Industrial Relations",
        link: "https://www.alabama.gov/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Alabama Department of Senior Services",
        link: "https://alabamaageline.gov/",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Public Health",
        link: "http://www.adph.org/Default.asp?bhcp=1",
      },
    ],
    justForFun: [
      "Alabama was the 22nd state incorporated in the US on December 14, 1819",
      "The Alabama state bird is the Willow Ptarmigan.",
      "Alabama is currently considered a Red state and voted Republican in the last presidential election",
      "There are 72 total colleges in Alabama",
      "Alabama ranks 46th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Alaska",
    currentStatistics:
      "Alaska ranks 51st among all states in population of people over 65. For the number of job opportunities for seniors it ranks 40th. Alaska accounts for 0.2% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Alaska you can visit ",
        linkTitle: "the Dept. of Labor & Workforce Development",
        link: "http://www.labor.state.ak.us/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Alaska Commission on Aging",
        link: "http://www.alaskaaging.org/",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Health & Social Services",
        link: "https://dhss.alaska.gov/pages/default.aspx",
      },
    ],
    justForFun: [
      "Alaska was the 49th state incorporated in the US on January 3, 1959",
      "The Alaska state bird is the Yellowhammer",
      "Alaska is currently considered a Red state and voted Republican in the last presidential election",
      "There are 7 total colleges in Alaska",
      "Alaska ranks 25th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Arizona",
    currentStatistics:
      "Arizona ranks 31st among all states in population of people over 65. For the number of job opportunities for seniors it ranks 19th. Arizona accounts for 2.1% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Arizona you can visit ",
        linkTitle: "the Department of Workforce Services",
        link: "http://www.state.ar.us/esd/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Behavioral Health & Aging Coalition",
        link: "http://www.azdhs.gov/bhs/aging.htm",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Health Services",
        link: "https://www.azdhs.gov/",
      },
    ],
    justForFun: [
      "Arizona was the 48th state incorporated in the US on February 14, 1912",
      "The Arizona state bird is the Mockingbird",
      "Arizona is currently considered a Red state and voted Republican in the last presidential election",
      "There are 78 total colleges in Arizona",
      "Arizona ranks 34th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Arkansas",
    currentStatistics:
      "Arkansas ranks 14th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 33rd. Arkansas accounts for 1.0% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Arkansas you can visit ",
        linkTitle: "the Department of Economic Security",
        link: "https://dws.arkansas.gov/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Division Aging & Adult Services",
        link: "http://www.state.ar.us/dhs/aging/",
      },
      {
        title: "For questions about health please visit",
        linkTitle: "the Department of Health ",
        link: "http://www.healthyarkansas.com/",
      },
    ],
    justForFun: [
      "Arkansas was the 25th state incorporated in the US on June 15, 1836",
      "The Arkansas state bird is the Cactus Wren",
      "Arkansas is currently considered a Purple state and voted Republican in the last presidential election",
      "There are 50 total colleges in Arkansas",
      "Arkansas ranks 18th in total GDP contribution out of all states",
    ],
  },
  {
    state: "California",
    currentStatistics:
      "California ranks 1st among all states in population of people over 65. For the number of job opportunities for seniors it ranks 1st. California accounts for 10.8% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of California you can visit ",
        linkTitle: "the Employment Development Dept.",
        link: "http://www.edd.ca.gov/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the California Department of Aging",
        link: "http://www.aging.ca.gov/",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Health Services",
        link: "https://www.dhcs.ca.gov/",
      },
    ],
    justForFun: [
      "California was the 31st state incorporated in the US on September 9, 1850",
      "The California state bird is the California Valley Quail",
      "California is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 436 total colleges in California",
      "California ranks 1st in total GDP contribution out of all states",
    ],
  },
  {
    state: "Colorado",
    currentStatistics:
      "Colorado ranks 26th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 24th. Colorado accounts for 1.5% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Colorado you can visit ",
        linkTitle: "the Dept. of Labor and Employment",
        link: "https://cdle.colorado.gov/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Aging and Adult Services",
        link: "https://cdhs.colorado.gov/our-services/older-adult-services",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Public Health & Environment",
        link: "https://cdphe.colorado.gov/",
      },
    ],
    justForFun: [
      "Colorado was the 38th state incorporated in the US on August 1, 1876",
      "The Colorado state bird is the Lark Bunting",
      "Colorado is currently considered a Purple state and voted Democrat in the last presidential election",
      "There are 75 total colleges in Colorado",
      "Colorado ranks 19th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Connecticut",
    currentStatistics:
      "Connecticut ranks 29th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 20th. Connecticut accounts for 1.2% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Connecticut you can visit ",
        linkTitle: "the Dept. of Labor",
        link: "http://www.ctdol.state.ct.us/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Aging Services Division",
        link: "https://portal.ct.gov/aginganddisability",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Public Health",
        link: "https://portal.ct.gov/DPH",
      },
    ],
    justForFun: [
      "Connecticut was the 5th state incorporated in the US on January 9, 1788",
      "The Connecticut state bird is the Robin",
      "Connecticut is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 43 total colleges in Connecticut",
      "Connecticut ranks 23rd in total GDP contribution out of all states",
    ],
  },
  {
    state: "Delaware",
    currentStatistics:
      "Delaware ranks 49th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 39th. Delaware accounts for 0.3% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Delaware you can visit ",
        linkTitle: "the Dept. of Employment Services",
        link: "https://labor.delaware.gov/divisions/employment-training/",
      },
      {
        title: "For aging services please visit ",
        linkTitle:
          "the Division of Services for Aging & Adults with Physical Disabilities",
        link: "http://www.dsaapd.com",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Division of Public Health",
        link: "https://dhss.delaware.gov/dhss/dph/index.html",
      },
    ],
    justForFun: [
      "Delaware was the 1st state incorporated in the US on December 7, 1787",
      "The Delaware state bird is the Blue Hen Chicken",
      "Delaware is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 10 total colleges in Delaware",
      "Delaware ranks 41st in total GDP contribution out of all states",
    ],
  },
  {
    state: "Florida",
    currentStatistics:
      "Florida ranks 2nd among all states in population of people over 65. For the number of job opportunities for seniors it ranks 4th. Florida accounts for 6.9% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Florida you can visit ",
        linkTitle: "the Agency for Workforce Innovation",
        link: "http://www.floridajobs.org/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Department of Elder Affairs",
        link: "https://oppaga.fl.gov/ProgramSummary/ProgramDetail?programNumber=5054",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Health",
        link: "http://www.doh.state.fl.us/",
      },
    ],
    justForFun: [
      "Florida was the 27th state incorporated in the US on March 3, 1845",
      "The Florida state bird is the Mockingbird",
      "Florida is currently considered a Purple state and voted Democrat in the last presidential election",
      "There are 207 total colleges in Florida",
      "Florida ranks 4th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Georgia",
    currentStatistics:
      "Georgia ranks 11th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 17th. Georgia accounts for 2.8% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Georgia you can visit ",
        linkTitle: "the Georgia Dept. of Labor",
        link: "http://www.dol.state.ga.us/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Divisionof Aging Services",
        link: "http://aging.dhr.georgia.gov/portal/site",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Division of Public Health",
        link: "http://health.state.ga.us/",
      },
    ],
    justForFun: [
      "Georgia was the 4th state incorporated in the US on January 2, 1788",
      "The Georgia state bird is the Brown Thrasher",
      "Georgia is currently considered a Red state and voted Republican in the last presidential election",
      "There are 131 total colleges in Georgia",
      "Georgia ranks 11th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Hawaii",
    currentStatistics:
      "Hawaii ranks 40th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 43rd. Hawaii accounts for 0.5% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Hawaii you can visit ",
        linkTitle: "the Dept. of Labor and Industrial Relations",
        link: "http://hawaii.gov/labor/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Executive Office on Aging",
        link: "https://health.hawaii.gov/eoa/",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the State Department of Health",
        link: "https://health.hawaii.gov/",
      },
    ],
    justForFun: [
      "Hawaii was the 50th state incorporated in the US on August 21, 1959",
      "The Hawaii state bird is the Nene",
      "Hawaii is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 20 total colleges in Hawaii",
      "Hawaii ranks 39th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Idaho",
    currentStatistics:
      "Idaho ranks 41st among all states in population of people over 65. For the number of job opportunities for seniors it ranks 44th. Idaho accounts for 0.5% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Idaho you can visit ",
        linkTitle: "the Idaho Dept. of Labor",
        link: "http://labor.idaho.gov/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Commission on Aging",
        link: "https://aging.idaho.gov/",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Health & Welfare",
        link: "https://healthandwelfare.idaho.gov/",
      },
    ],
    justForFun: [
      "Idaho was the 43rd state incorporated in the US on July 3, 1890",
      "The Idaho state bird is the Cardinal",
      "Idaho is currently considered a Red state and voted Republican in the last presidential election",
      "There are 15 total colleges in Idaho",
      "Idaho ranks 5th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Illinois",
    currentStatistics:
      "Illinois ranks 7th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 5th. Illinois accounts for 4.1% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Illinois you can visit ",
        linkTitle: "the Dept. of Employment Security",
        link: "https://ides.illinois.gov/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Illinois Department on Aging",
        link: "http://www.state.il.us/aging/",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Public Health",
        link: "http://www.idph.state.il.us/",
      },
    ],
    justForFun: [
      "Illinois was the 21st state incorporated in the US on December 3, 1818",
      "The Illinois state bird is the Cardinal",
      "Illinois is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 181 total colleges in Illinois",
      "Illinois ranks 16th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Indiana",
    currentStatistics:
      "Indiana ranks 16th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 13th. Indiana accounts for 2.1% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Indiana you can visit ",
        linkTitle: "the Dept. of Workforce Development",
        link: "http://www.in.gov/dwd/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Division of Aging & Adult Services",
        link: "https://www.in.gov/fssa/da/",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the State Department of Health",
        link: "https://www.cms.gov/contacts/state-department-health-indiana-health-care-regulatory-services/general-beneficiary-contact/1552191",
      },
    ],
    justForFun: [
      "Indiana was the 19th state incorporated in the US on December 11, 1816",
      "The Indiana state bird is the Eastern Goldfinch",
      "Indiana is currently considered a Red state and voted Republican in the last presidential election",
      "There are 106 total colleges in Indiana",
      "Indiana ranks 30th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Iowa",
    currentStatistics:
      "Iowa ranks 30th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 27th. Iowa accounts for 1.1% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Iowa you can visit ",
        linkTitle: "the Iowa Workforce Development",
        link: "https://www.iowaworkforcedevelopment.gov/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Department of Elder Affairs",
        link: "https://nrd.gov/resource/detail/8884204/Iowa+Department+on+Aging",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Public Health",
        link: "https://hhs.iowa.gov/",
      },
    ],
    justForFun: [
      "Iowa was the 29th state incorporated in the US on December 28, 1846",
      "The Iowa state bird is the American Goldfinch",
      "Iowa is currently considered a Red state and voted Republican in the last presidential election",
      "There are 65 total colleges in Iowa",
      "Iowa ranks 43rd in total GDP contribution out of all states",
    ],
  },
  {
    state: "Kansas",
    currentStatistics:
      "Kansas ranks 33rd among all states in population of people over 65. For the number of job opportunities for seniors it ranks 29th. Kansas accounts for 0.9% of all workers over 55 in the United Statesю",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Kansas you can visit ",
        linkTitle: "the Kansas Department of Labor",
        link: "https://www.dol.ks.gov/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Department on Aging",
        link: "http://www.agingkansas.org/index.htm",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Health & Environment",
        link: "http://www.kdhe.state.ks.us/",
      },
    ],
    justForFun: [
      "Kansas was the 34th state incorporated in the US on January 29, 1861",
      "The Kansas state bird is the Western Meadowlark",
      "Kansas is currently considered a Red state and voted Republican in the last presidential election",
      "There are 67 total colleges in Kansas",
      "Kansas ranks 31st in total GDP contribution out of all states",
    ],
  },
  {
    state: "Kentucky",
    currentStatistics:
      "Kentucky ranks 24th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 25th. Kentucky accounts for 1.5% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Kentucky you can visit ",
        linkTitle: "the Office of Employment and Training",
        link: "http://oet.ky.gov/index.asp",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Department for Aging and Independent Living",
        link: "https://nrd.gov/resource/detail/8884229/Kentucky+Aging+%26+Disability+Resource+Center+%28ADRC%29",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Cabinet for Health & Family Services",
        link: "https://www.cms.gov/contacts/cabinet-health-services-kentucky/general-professional-contact/1560931",
      },
    ],
    justForFun: [
      "Kentucky was the 15th state incorporated in the US on June 1, 1792",
      "The Kentucky state bird is the Cardinal",
      "Kentucky is currently considered a Red state and voted Republican in the last presidential election",
      "There are 75 total colleges in Kentucky",
      "Kentucky ranks 28th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Louisiana",
    currentStatistics:
      "Louisiana ranks 25th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 28th. Louisiana accounts for 1.4% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Louisiana you can visit ",
        linkTitle: "the Louisiana Workforce Commission",
        link: "https://www.laworks.net/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Governor's Office of Elderly Affairs",
        link: "https://gov.louisiana.gov/page/elderly-affairs",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Office of Public Health",
        link: "https://ldh.la.gov/subhome/16",
      },
    ],
    justForFun: [
      "Louisiana was the 18th state incorporated in the US on April 30, 1812",
      "The Louisiana state bird is the Eastern Brown Pelican",
      "Louisiana is currently considered a Red state and voted Republican in the last presidential election",
      "There are 87 total colleges in Louisiana",
      "Louisiana ranks 24th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Maine",
    currentStatistics:
      "Maine ranks 39th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 47th. Maine accounts for 0.5% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Maine you can visit ",
        linkTitle: "the Maine Dept. of Labor",
        link: "http://www.maine.gov/labor/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Office of Elder Services",
        link: "https://www.nrd.gov/resource/detail/8873390/Maine+Office+of+Aging+%26+Disability+Services+%28OADS%29+",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Maine CDC",
        link: "http://www.maine.gov/dhhs/boh/index.htm",
      },
    ],
    justForFun: [
      "Maine was the 23rd state incorporated in the US on March 19, 1820",
      "The Maine state bird is the Chickadee",
      "Maine is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 30 total colleges in Maine",
      "Maine ranks 12th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Maryland",
    currentStatistics:
      "Maryland ranks 20th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 18th. Maryland accounts for 1.8% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Maryland you can visit ",
        linkTitle: "the Division of Labor, Licensing & Regulation",
        link: "http://www.dllr.state.md.us/employment/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Department of Aging",
        link: "http://www.mdoa.state.md.us/",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Health & Mental Hygiene",
        link: "https://health.maryland.gov/Pages/Home.aspx",
      },
    ],
    justForFun: [
      "Maryland was the 7th state incorporated in the US on April 28, 1788",
      "The Maryland state bird is the Baltimore Oriole",
      "Maryland is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 59 total colleges in Maryland",
      "Maryland ranks 15th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Massachusetts",
    currentStatistics:
      "Massachusetts ranks 13th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 10th. Massachusetts accounts for 2.3% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Massachusetts you can visit ",
        linkTitle: "the Labor and Workforce Development",
        link: "http://www.mass.gov/?pageID=elwdhomepage&L=1&L0=Home&sid=Elwd",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Executive Office of Elder Affairs",
        link: "https://www.mass.gov/orgs/executive-office-of-elder-affairs",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Public Health",
        link: "https://www.mass.gov/orgs/department-of-public-health",
      },
    ],
    justForFun: [
      "Massachusetts was the 6th state incorporated in the US on February 6, 1788",
      "The Massachusetts state bird is the Chickadee",
      "Massachusetts is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 125 total colleges in Massachusetts",
      "Massachusetts ranks 44th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Michigan",
    currentStatistics:
      "Michigan ranks 8th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 11th. Michigan accounts for 3.4% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Michigan you can visit ",
        linkTitle: "the Michigan Career Portal",
        link: "https://www.michigan.gov/som/job-seekers",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Office of Services to the Aging",
        link: "https://nrd.gov/resource/detail/8895096/Michigan+Aging+%26+Adult+Services+Agency+-+Aging+Services",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Community Health",
        link: "https://www.michigan.gov/mdcs/state-employment/general/michigan-department-of-community-health",
      },
    ],
    justForFun: [
      "Michigan was the 26th state incorporated in the US on January 26, 1837",
      "The Michigan state bird is the Robin",
      "Michigan is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 105 total colleges in Michigan",
      "Michigan ranks 13th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Minnesota",
    currentStatistics:
      "Minnesota ranks 21st among all states in population of people over 65. For the number of job opportunities for seniors it ranks 15th. Minnesota accounts for 1.7% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Minnesota you can visit ",
        linkTitle: "the Minnesota Workforce Center",
        link: "http://www.mnworkforcecenter.org/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Board on Aging",
        link: "http://www.mnaging.org/",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Health ",
        link: "http://www.health.state.mn.us/",
      },
    ],
    justForFun: [
      "Minnesota was the 32nd state incorporated in the US on May 11, 1858",
      "The Minnesota state bird is the Common Loon",
      "Minnesota is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 117 total colleges in Minnesota",
      "Minnesota ranks 17th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Mississippi",
    currentStatistics:
      "Mississippi ranks 32nd among all states in population of people over 65. For the number of job opportunities for seniors it ranks 37th. Mississippi accounts for 0.9% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Mississippi you can visit ",
        linkTitle: "the Department of Employment Security",
        link: "https://mdes.ms.gov/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Division of Aging & Adult Services",
        link: "https://www.ms.gov/Agencies/division-aging-and-adult-services-daas-mississippi-department-human-services",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Health",
        link: "https://msdh.ms.gov/",
      },
    ],
    justForFun: [
      "Mississippi was the 20th state incorporated in the US on December 10, 1817",
      "The Mississippi state bird is the Bluebird",
      "Mississippi is currently considered a Red state and voted Republican in the last presidential election",
      "There are 41 total colleges in Mississippi",
      "Mississippi ranks 22nd in total GDP contribution out of all states",
    ],
  },
  {
    state: "Missouri",
    currentStatistics:
      "Missouri ranks 17th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 23rd. Missouri accounts for 2.0% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Missouri you can visit ",
        linkTitle: "the Department of Labor & Industrial Relations",
        link: "https://www.mo.gov/government/guide-to-missouris-government/department-of-labor-and-industrial-relations/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Department of Health & Senior Services",
        link: "http://www.dhss.mo.gov/",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Health",
        link: "http://health.mo.gov/",
      },
    ],
    justForFun: [
      "Missouri was the 24th state incorporated in the US on August 10, 1821",
      "The Missouri state bird is the Mockingbird",
      "Missouri is currently considered a Purple state and voted Democrat in the last presidential election",
      "There are 132 total colleges in Missouri",
      "Missouri ranks 36th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Montana",
    currentStatistics:
      "Montana ranks 44th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 46th. Montana accounts for 0.4% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Montana you can visit ",
        linkTitle: "the Dept. of Labor and Industry",
        link: "http://www.dli.mt.gov/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Senior & Long Term Care Division",
        link: "https://dphhs.mt.gov/sltc/sltcdivcontact",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Public Health & Human Services",
        link: "https://dphhs.mt.gov/",
      },
    ],
    justForFun: [
      "Montana was the 41st state incorporated in the US on November 8, 1889",
      "The Montana state bird is the Western Meadowlark",
      "Montana is currently considered a Red state and voted Republican in the last presidential election",
      "There are 23 total colleges in Montana",
      "Montana ranks 49th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Nebraska",
    currentStatistics:
      "Nebraska ranks 38th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 41st. Nebraska accounts for 0.6% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Nebraska you can visit ",
        linkTitle: "the Nebraska Dept. of Labor",
        link: "https://nrd.gov/resource/detail/12744248/Nebraska+Department+of+Labor",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Aging Services",
        link: "https://dhhs.ne.gov/pages/aging.aspxhttps://dhhs.ne.gov/pages/aging.aspx",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Health & Human Services",
        link: "https://www.fns.usda.gov/fns-contact/nebraska-department-health-human-services-1",
      },
    ],
    justForFun: [
      "Nebraska was the 37th state incorporated in the US on March 1, 1867",
      "The Nebraska state bird is the Purple Finch",
      "Nebraska is currently considered a Red state and voted Republican in the last presidential election",
      "There are 43 total colleges in Nebraska",
      "Nebraska ranks 42nd in total GDP contribution out of all states",
    ],
  },
  {
    state: "Nevada",
    currentStatistics:
      "Nevada ranks 34th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 21st. Nevada accounts for 0.8% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Nevada you can visit ",
        linkTitle: "the Dept. of Employment, Training and Rehabilitation",
        link: "http://detr.state.nv.us/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Nevada Division for Aging Services",
        link: "https://nrd.gov/resource/detail/12744277/Nevada+Department+of+Health+%26+Human+Services+-+Aging+%26+Disability+Services+Division",
      },
      {
        title: "For questions about health please visit  ",
        linkTitle: "the State Health Division",
        link: "https://dhhs.nv.gov/",
      },
    ],
    justForFun: [
      "Nevada was the 36th state incorporated in the US on October 31, 1864",
      "The Nevada state bird is the Cardinal",
      "Nevada is currently considered a Purple state and voted Republican in the last presidential election",
      "There are 21 total colleges in Nevada",
      "Nevada ranks 10th in total GDP contribution out of all states",
    ],
  },
  {
    state: "New Hampshire",
    currentStatistics:
      "New Hampshire ranks 42nd among all states in population of people over 65. For the number of job opportunities for seniors it ranks 35th. New Hampshire accounts for 0.5% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of New Hampshire you can visit ",
        linkTitle: "the Employment Security",
        link: "https://www.nhes.nh.gov/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Bureau of Elderly & Adult Services",
        link: "https://www.dhhs.nh.gov/bureau-elderly-adult-services",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Health & Human Services",
        link: "https://www.dhhs.nh.gov/",
      },
    ],
    justForFun: [
      "New Hampshire was the 9th state incorporated in the US on June 21, 1788",
      "The New Hampshire state bird is the Eastern Goldfinch",
      "New Hampshire is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 28 total colleges in New Hampshire",
      "New Hampshire ranks 7th in total GDP contribution out of all states",
    ],
  },
  {
    state: "New Jersey",
    currentStatistics:
      "New Jersey ranks 10th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 7th. New Jersey accounts for 2.9% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of New Jersey you can visit ",
        linkTitle: "the Work Force New Jersey",
        link: "https://www.nj.gov/labor/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Aging & Community Services",
        link: "https://www.nj.gov/humanservices/doas/home/",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Health & Senior Services",
        link: "http://www.state.nj.us/health/",
      },
    ],
    justForFun: [
      "New Jersey was the 3rd state incorporated in the US on December 18, 1787",
      "The New Jersey state bird is the Roadrunner",
      "New Jersey is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 65 total colleges in New Jersey",
      "New Jersey ranks 38th in total GDP contribution out of all states",
    ],
  },
  {
    state: "New Mexico",
    currentStatistics:
      "New Mexico ranks 36th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 38th. New Mexico accounts for 0.7% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of New Mexico you can visit ",
        linkTitle: "the New Mexico Department of Workforce Solutions",
        link: "http://www.dws.state.nm.us/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Aging & Long Term Care Services Department",
        link: "http://www.nmaging.state.nm.us/",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Health",
        link: "https://www.nm.gov/departments-and-agencies/department-of-health/",
      },
    ],
    justForFun: [
      "New Mexico was the 47th state incorporated in the US on January 6, 1912",
      "The New Mexico state bird is the Bluebird",
      "New Mexico is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 41 total colleges in New Mexico",
      "New Mexico ranks 3rd in total GDP contribution out of all states",
    ],
  },
  {
    state: "New York",
    currentStatistics:
      "New York ranks 3rd among all states in population of people over 65. For the number of job opportunities for seniors it ranks 6th. New York accounts for 6.6% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of New York you can visit ",
        linkTitle: "the New York Dept. of Labor",
        link: "https://dol.ny.gov/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the State Office for the Aging",
        link: "https://aging.ny.gov/",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Health",
        link: "https://www.health.ny.gov/",
      },
    ],
    justForFun: [
      "New York was the 11th state incorporated in the US on July 26, 1788",
      "The New York state bird is the Western Meadowlark",
      "New York is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 306 total colleges in New York",
      "New York ranks 50th in total GDP contribution out of all states",
    ],
  },
  {
    state: "North Carolina",
    currentStatistics:
      "North Carolina ranks 9th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 12th. North Carolina accounts for 3.1% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of North Carolina you can visit ",
        linkTitle: "the N.C. Employment Security Commission",
        link: "http://www.ncesc.com/splash.asp",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Division of Aging & Adult Services",
        link: "https://www.ncdhhs.gov/divisions/aging-and-adult-services",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Health & Human Services",
        link: "https://www.ncdhhs.gov/",
      },
    ],
    justForFun: [
      "North Carolina was the 12th state incorporated in the US on November 21, 1789",
      "The North Carolina state bird is the Western Meadowlark",
      "North Carolina is currently considered a Red state and voted Republican in the last presidential election",
      "There are 137 total colleges in North Carolina",
      "North Carolina ranks 37th in total GDP contribution out of all states",
    ],
  },
  {
    state: "North Dakota",
    currentStatistics:
      "North Dakota ranks 47th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 45th. North Dakota accounts for 0.2% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of North Dakota you can visit ",
        linkTitle: "the North Dakota Job Service",
        link: "http://www.jobsnd.com/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Department of Human Services, Aging Services Division",
        link: "http://www.state.nd.us/humanservices/services/adultsaging/",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Health",
        link: "http://www.health.state.nd.us/",
      },
    ],
    justForFun: [
      "North Dakota was the 39th state incorporated in the US on November 2, 1889",
      "The North Dakota state bird is the Mountain Bluebird",
      "North Dakota is currently considered a Red state and voted Republican in the last presidential election",
      "There are 22 total colleges in North Dakota",
      "North Dakota ranks 32nd in total GDP contribution out of all states",
    ],
  },
  {
    state: "Ohio",
    currentStatistics:
      "Ohio ranks 6th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 8th. Ohio accounts for 4.0% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Ohio you can visit ",
        linkTitle: "the Dept. of Job and Family Services",
        link: "http://jfs.ohio.gov/",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Department of Aging",
        link: "https://aging.ohio.gov/",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Health",
        link: "https://odh.ohio.gov/",
      },
    ],
    justForFun: [
      "Ohio was the 17th state incorporated in the US on March 1, 1803",
      "The Ohio state bird is the Cardinal",
      "Ohio is currently considered a Purple state and voted Democrat in the last presidential election",
      "There are 213 total colleges in Ohio",
      "Ohio ranks 8th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Oklahoma",
    currentStatistics:
      "Oklahoma ranks 28th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 30th. Oklahoma accounts for 1.2% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Oklahoma you can visit ",
        linkTitle: "the Employment Security Commission",
        link: "https://oklahoma.gov/oesc.html",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Aging Services Division",
        link: "http://www.okdhs.org/divisionsoffices/visd/asd/",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the State Department of Health",
        link: "http://www.health.state.ok.us/",
      },
    ],
    justForFun: [
      "Oklahoma was the 46th state incorporated in the US on November 16, 1907",
      "The Oklahoma state bird is the Scissor-tailed Flycatcher",
      "Oklahoma is currently considered a Red state and voted Republican in the last presidential election",
      "There are 61 total colleges in Oklahoma",
      "Oklahoma ranks 29th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Oregon",
    currentStatistics:
      "Oregon ranks 27th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 32nd. Oregon accounts for 1.4% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Oregon you can visit ",
        linkTitle: "the Oregon Employment Dept.",
        link: "http://www.employment.oregon.gov/",
      },
      {
        title: "For aging services please visit ",
        linkTitle:
          "the Department of Human Services: Seniorsand People With Physical Disabilities",
        link: "https://www.oregon.gov/odhs/aging-disability-services/pages/default.aspx",
      },
      {
        title: "For questions about health please visit ",
        linkTitle: "the Department of Human Services: Public Health Division",
        link: "https://www.oregon.gov/oha/ph/pages/index.aspx",
      },
    ],
    justForFun: [
      "Oregon was the 33rd state incorporated in the US on February 14, 1859",
      "The Oregon state bird is the Western Meadowlark",
      "Oregon is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 60 total colleges in Oregon",
      "Oregon ranks 26th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Pennsylvania",
    currentStatistics:
      "Pennsylvania ranks 5th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 3rd. Pennsylvania accounts for 4.6% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Pennsylvania you can visit",
        linkTitle: "the Penn. Dept. of Labor and Industry",
        link: "https://www.dli.pa.gov/Pages/default.aspx",
      },
      {
        title: "For aging services please visit",
        linkTitle: "the Department of Aging",
        link: "https://www.aging.pa.gov/Pages/default.aspx",
      },
      {
        title: "For questions about health please visit",
        linkTitle: "the Department of Health",
        link: "https://www.health.pa.gov/Pages/default.aspx",
      },
    ],
    justForFun: [
      "Pennsylvania was the 2nd state incorporated in the US on December 12, 1787",
      "The Pennsylvania state bird is the Ruffed Grouse",
      "Pennsylvania is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 257 total colleges in Pennsylvania",
      "Pennsylvania ranks 6th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Rhode Island",
    currentStatistics:
      "Rhode Island ranks 43rd among all states in population of people over 65. For the number of job opportunities for seniors it ranks 49th. Rhode Island accounts for 0.4% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Rhode Island you can visit",
        linkTitle: "the RI Dept. of Labor and Training",
        link: "http://www.dlt.ri.gov/",
      },
      {
        title: "For aging services please visit",
        linkTitle: "the Department of Elderly Affairs",
        link: "https://oha.ri.gov/",
      },
      {
        title: "For questions about health please visit",
        linkTitle: "the Department of Health",
        link: "https://nrd.gov/resource/detail/9918397/Rhode+Island+Department+of+Health",
      },
    ],
    justForFun: [
      "Rhode Island was the 13th state incorporated in the US on May 29, 1790",
      "The Rhode Island state bird is the Rhode Island Red",
      "Rhode Island is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 13 total colleges in Rhode Island",
      "Rhode Island ranks 45th in total GDP contribution out of all states",
    ],
  },
  {
    state: "South Carolina",
    currentStatistics:
      "South Carolina ranks 23rd among all states in population of people over 65. For the number of job opportunities for seniors it ranks 26th. South Carolina accounts for 1.6% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of South Carolina you can visit",
        linkTitle: "the SC Employment Security Commission",
        link: "https://dc.statelibrary.sc.gov/handle/10827/16255",
      },
      {
        title: "For aging services please visit ",
        linkTitle: "the Lieutenant Governor's Office on Aging",
        link: "https://aging.sc.gov/",
      },
      {
        title: "For questions about health please visit",
        linkTitle: "the Department of Health & Environmental Control",
        link: "http://www.scdhec.gov",
      },
    ],
    justForFun: [
      "South Carolina was the 8th state incorporated in the US on May 23, 1788",
      "The South Carolina state bird is the Great Carolina Wren",
      "South Carolina is currently considered a Red state and voted Republican in the last presidential election",
      "There are 71 total colleges in South Carolina",
      "South Carolina ranks 27th in total GDP contribution out of all states",
    ],
  },
  {
    state: "South Dakota",
    currentStatistics:
      "South Dakota ranks 46th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 50th. South Dakota accounts for 0.3% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of South Dakota you can visit",
        linkTitle: "the South Dakota Dept. of Labor",
        link: "http://dol.sd.gov/",
      },
      {
        title: "For aging services please visit",
        linkTitle: "the Department of Social Services: Elderly Services",
        link: "https://dss.sd.gov/",
      },
      {
        title: "For questions about health please visit",
        linkTitle: "the Department of Health",
        link: "https://doh.sd.gov/",
      },
    ],
    justForFun: [
      "South Dakota was the 40th state incorporated in the US on November 2, 1889",
      "The South Dakota state bird is the Ring-necked Pheasant",
      "South Dakota is currently considered a Red state and voted Republican in the last presidential election",
      "There are 25 total colleges in South Dakota",
      "South Dakota ranks 47th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Tennessee",
    currentStatistics:
      "Tennessee ranks 15th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 14th. Tennessee accounts for 2.1% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Tennessee you can visit",
        linkTitle: "the Dept. of Labor & Workforce Development",
        link: "https://www.tn.gov/workforce.html",
      },
      {
        title: "For aging services please visit",
        linkTitle: "the Commission on Aging & Disability",
        link: "https://www.tn.gov/aging.html",
      },
      {
        title: "For questions about health please visit",
        linkTitle: "the Department of Health",
        link: "https://www.tn.gov/health.html",
      },
    ],
    justForFun: [
      "Tennessee was the 16th state incorporated in the US on June 1, 1796",
      "The Tennessee state bird is the Mockingbird",
      "Tennessee is currently considered a Red state and voted Republican in the last presidential election",
      "There are 106 total colleges in Tennessee",
      "Tennessee ranks 21st in total GDP contribution out of all states",
    ],
  },
  {
    state: "Texas",
    currentStatistics:
      "Texas ranks 4th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 2nd. Texas accounts for 6.8% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Texas you can visit",
        linkTitle: "the Texas Workforce Commission",
        link: "http://www.twc.state.tx.us/",
      },
      {
        title: "For aging services please visit",
        linkTitle: "the Department of Aging & Disability Services",
        link: "https://www.hhs.texas.gov/providers/long-term-care-providers/aging-disability-resource-center-adrc",
      },
      {
        title: "For questions about health please visit",
        linkTitle: "the Department of State Health Services",
        link: "http://www.dshs.state.tx.us/",
      },
    ],
    justForFun: [
      "Texas was the 28th state incorporated in the US on December 29, 1845",
      "The Texas state bird is the Mockingbird",
      "Texas is currently considered a Red state and voted Republican in the last presidential election",
      "There are 240 total colleges in Texas",
      "Texas ranks 2nd in total GDP contribution out of all states",
    ],
  },
  {
    state: "Utah",
    currentStatistics:
      "Utah ranks 37th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 34th. Utah accounts for 0.7% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Utah you can visit",
        linkTitle: "the Dept. of Workforce Services",
        link: "https://www.utah.gov/employment/",
      },
      {
        title: "For aging services please visit",
        linkTitle: "the Division of Aging & Adult Services",
        link: "https://daas.utah.gov/",
      },
      {
        title: "For questions about health please visit",
        linkTitle: "the Department of Health",
        link: "http://health.utah.gov",
      },
    ],
    justForFun: [
      "Utah was the 45th state incorporated in the US on January 4, 1896",
      "The Utah state bird is the Common American Gull",
      "Utah is currently considered a Red state and voted Republican in the last presidential election",
      "There are 40 total colleges in Utah",
      "Utah ranks 33rd in total GDP contribution out of all states",
    ],
  },
  {
    state: "Vermont",
    currentStatistics:
      "Vermont ranks 48th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 48th. Vermont accounts for 0.2% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Vermont you can visit",
        linkTitle: "the Dept. of Labor",
        link: "http://labor.vermont.gov/",
      },
      {
        title: "For aging services please visit",
        linkTitle: "the Department of Disabilities, Aging & Independent Living",
        link: "http://dail.vermont.gov/",
      },
      {
        title: "For questions about health please visit",
        linkTitle: "the Department of Health",
        link: "http://healthvermont.gov/",
      },
    ],
    justForFun: [
      "Vermont was the 14th state incorporated in the US on March 4, 1791",
      "The Vermont state bird is the Cardinal",
      "Vermont is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 25 total colleges in Vermont",
      "Vermont ranks 9th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Virginia",
    currentStatistics:
      "Virginia ranks 12th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 9th. Virginia accounts for 2.5% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Virginia you can visit",
        linkTitle: "the Virginia Employment Commission",
        link: "http://www.vec.virginia.gov/vecportal/",
      },
      {
        title: "For aging services please visit",
        linkTitle: "the Department for the Aging",
        link: "http://www.vda.virginia.gov/",
      },
      {
        title: "For questions about health please visit",
        linkTitle: "the Department of Health",
        link: "https://www.vdh.virginia.gov/",
      },
    ],
    justForFun: [
      "Virginia was the 10th state incorporated in the US on June 25, 1788",
      "The Virginia state bird is the Cardinal",
      "Virginia is currently considered a Red state and voted Republican in the last presidential election",
      "There are 119 total colleges in Virginia",
      "Virginia ranks 51st in total GDP contribution out of all states",
    ],
  },
  {
    state: "Washington",
    currentStatistics:
      "Washington ranks 18th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 22nd. Washington accounts for 2.2% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Washington you can visit",
        linkTitle: "the Employment Security Department",
        link: "http://www.esd.wa.gov/",
      },
      {
        title: "For aging services please visit",
        linkTitle: "the Aging & Disability Services Administration",
        link: "https://www.dshs.wa.gov/altsa",
      },
      {
        title: "For questions about health please visit",
        linkTitle: "the State Department of Health",
        link: "http://www.doh.wa.gov/",
      },
    ],
    justForFun: [
      "Washington was the 42nd state incorporated in the US on November 11, 1889",
      "The Washington state bird is the Willow Goldfinch",
      "Washington is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 81 total colleges in Washington",
      "Washington ranks 14th in total GDP contribution out of all states",
    ],
  },
  {
    state: "West Virginia",
    currentStatistics:
      "West Virginia ranks 35th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 42nd. West Virginia accounts for 0.7% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of West Virginia you can visit",
        linkTitle: "the Workforce West Virginia",
        link: "http://www.wvbep.org/bep/default.htm",
      },
      {
        title: "For aging services please visit",
        linkTitle: "the Bureau of Senior Services",
        link: "http://www.state.wv.us/seniorservices/",
      },
      {
        title: "For questions about health please visit",
        linkTitle: "the Department of Health & Human Resources",
        link: "https://dhhr.wv.gov/Pages/default.aspx",
      },
    ],
    justForFun: [
      "West Virginia was the 35th state incorporated in the US on June 20, 1863",
      "The West Virginia state bird is the Robin",
      "West Virginia is currently considered a Purple state and voted Republican in the last presidential election",
      "There are 45 total colleges in West Virginia",
      "West Virginia ranks 20th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Wisconsin",
    currentStatistics:
      "Wisconsin ranks 19th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 16th. Wisconsin accounts for 1.9% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Wisconsin you can visit",
        linkTitle: "the Dept. of Workforce Development",
        link: "https://dhhr.wv.gov/Pages/default.aspx",
      },
      {
        title: "For aging services please visit",
        linkTitle: "the Services for the Elderly",
        link: "https://www.dhs.wisconsin.gov/aging/index.htm",
      },
      {
        title: "For questions about health please visit",
        linkTitle: "the Department of Health & Family Services",
        link: "https://www.dhs.wisconsin.gov/",
      },
    ],
    justForFun: [
      "Wisconsin was the 30th state incorporated in the US on May 29, 1848",
      "The Wisconsin state bird is the Cardinal",
      "Wisconsin is currently considered a Blue state and voted Democrat in the last presidential election",
      "There are 77 total colleges in Wisconsin",
      "Wisconsin ranks 40th in total GDP contribution out of all states",
    ],
  },
  {
    state: "Wyoming",
    currentStatistics:
      "Wyoming ranks 50th among all states in population of people over 65. For the number of job opportunities for seniors it ranks 51st. Wyoming accounts for 0.2% of all workers over 55 in the United States.",
    moreRecources: [
      {
        title:
          "For official employment information from the government of Wyoming you can visit",
        linkTitle: "the Wyoming Dept. of Employment",
        link: "https://dws.wyo.gov/",
      },
      {
        title: "For aging services please visit",
        linkTitle: "the Department of Health Aging Division",
        link: "https://health.wyo.gov/aging/",
      },
      {
        title: "For questions about health please visit",
        linkTitle: "the Department of Health",
        link: "https://health.wyo.gov",
      },
    ],
    justForFun: [
      "Wyoming was the 44th state incorporated in the US on July 10, 1890",
      "The Wyoming state bird is the Western Meadowlark",
      "Wyoming is currently considered a Red state and voted Republican in the last presidential election",
      "There are 11 total colleges in Wyoming",
      "Wyoming ranks 48th in total GDP contribution out of all states",
    ],
  },
];
